'use client';

import { fromNowDate } from '@/utils/time/localeDate';
import { useEffect, useState } from 'react';

export const FromNowDateClient = ({
  date,
  languageCode,
  onDateFound,
}: {
  date?: string;
  languageCode?: string;
  onDateFound?: () => void;
}) => {
  const [fromDate, setFromDate] = useState('');
  useEffect(() => {
    async function getDate() {
      const d = await fromNowDate(date, languageCode);
      setFromDate(d);
    }
    getDate().then(() => onDateFound?.());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, languageCode]);

  return fromDate;
};

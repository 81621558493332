'use client';
import { useEffect, useState } from 'react';
import { LiveIcon } from '@/app/components/icons';
import { BreakingNew, Channel } from '@/lib/model';
import clsx from 'clsx';
import { EmblaCarouselType } from 'embla-carousel';
import dayjs from 'dayjs';
import { useSocket } from '@/hooks/useSocket';
import {
  BreakingNewsContent,
  BreakingNewsDots,
} from '@/app/components/notification';
import { useTranslations } from 'next-intl';

type Props = {
  initialBreakingNews?: BreakingNew[];
  channel: Channel;
};

export function BreakingWrapper({ initialBreakingNews, channel }: Props) {
  const t = useTranslations();
  const { breakingNewsUpdates } = useSocket();
  const [emblaApi, setCarouselApi] = useState<EmblaCarouselType | null>(null);
  const [breakingNews, setBreakingNews] = useState<BreakingNew[]>([]);
  const dateDiffAsMinutes = (date?: string): number => {
    const now = dayjs();
    const startTime = dayjs(date);
    return now.diff(startTime, 'minute');
  };

  // First initial breaking news filtering
  useEffect(() => {
    if (initialBreakingNews) {
      const filteredNews =
        initialBreakingNews?.filter(
          b => b.isPinned || dateDiffAsMinutes(b?.updatedAt) < 10,
        ) || [];
      setBreakingNews(filteredNews);
    }
  }, [initialBreakingNews]);

  // Check every minute for 10 min feature
  useEffect(() => {
    const timer = setInterval(() => {
      const filteredNews =
        breakingNews?.filter(
          b => b.isPinned || dateDiffAsMinutes(b?.updatedAt) < 10,
        ) || [];
      setBreakingNews(filteredNews);
    }, 60000);

    return () => clearInterval(timer);
  }, [breakingNews]);

  // If socket trigger, update or add new item
  useEffect(() => {
    const channelBreakingNewsUpdates = breakingNewsUpdates.filter(
      b => b.channel === channel?.id,
    );

    if (channelBreakingNewsUpdates.length > 0) {
      const updatedItem =
        channelBreakingNewsUpdates[channelBreakingNewsUpdates.length - 1];
      const findIndex = breakingNews?.findIndex(x => x.id === updatedItem?.id);
      if (findIndex < 0) {
        setBreakingNews(olds => [...olds, updatedItem]);
      } else {
        const newArray = breakingNews?.map(x =>
          x.id === updatedItem.id ? updatedItem : x,
        );
        setBreakingNews(newArray);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breakingNewsUpdates]);

  if (breakingNews?.length === 0) return null;
  const slicedBreakingNews = breakingNews?.slice(0, 7);
  return (
    <div className="z-[10] relative w-full flex flex-col lg:flex-row">
      <div className="xl:min-w-[214px] lg:min-w-[188px] flex flex-row justify-center gap-[9px] bg-grey-3">
        <div className="my-auto">
          <LiveIcon className="text-breaking-text" />
        </div>

        <div className="font-sf text-xl md:text-2xl font-extrabold text-breaking-text uppercase my-auto">
          {t('breaking')}
        </div>
      </div>

      <div
        className={clsx(
          'bg-breaking-background w-full flex flex-row xl:max-h-[100px] md:max-h-[120px] overflow-hidden max-h-fit',
        )}
      >
        <BreakingNewsDots
          emblaApi={emblaApi}
          breakingNews={slicedBreakingNews}
        />
        <BreakingNewsContent
          emblaApi={emblaApi}
          setCarouselApi={setCarouselApi}
          breakingNews={slicedBreakingNews}
        />
      </div>
    </div>
  );
}

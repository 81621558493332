import { BreakingNew, LiveCoverage } from '@/lib/model';
import { useEffect, useState, useCallback } from 'react';
import io, { Socket } from 'socket.io-client';

export const useSocket = () => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [breakingNewsUpdates, setBreakingNewsUpdates] = useState<BreakingNew[]>(
    [],
  );
  const [liveCoverageUpdates, setLiveCoverageUpdates] = useState<
    LiveCoverage[]
  >([]);

  const connectToSocketServer = useCallback(() => {
    const socketServer = io(process.env.NEXT_PUBLIC_SOCKET_URL ?? '', {
      auth: {
        token: `Bearer ${process.env.NEXT_PUBLIC_SOCKET_TOKEN}`,
      },
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      timeout: 20000,
      transports: ['websocket'],
      autoConnect: false,
    });

    socketServer.on('connect', () => {
      socketServer.on('breaking-news', update => {
        setBreakingNewsUpdates(prev => [...prev, update]);
      });

      socketServer.on('live-coverage', update => {
        setLiveCoverageUpdates(prev => [...prev, update]);
      });
    });

    socketServer.on('disconnect', reason => {
      console.warn('Disconnected from Socket Server:', reason);
    });

    socketServer.on('error', error => {
      console.error('Socket connection error:', error);
    });

    socketServer.on('connect_error', error => {
      console.error('Connection error:', error);
    });

    return socketServer;
  }, []);

  useEffect(() => {
    let mounted = true;

    if (typeof window !== 'undefined') {
      const socketServer = connectToSocketServer();

      const connectWithDelay = () => {
        if (mounted) {
          socketServer.connect();
        }
      };

      window.requestAnimationFrame(() => {
        setTimeout(connectWithDelay, 100);
      });

      setSocket(socketServer);

      return () => {
        mounted = false;
        if (socketServer) {
          socketServer.removeAllListeners();
          socketServer.disconnect();
        }
      };
    }
  }, [connectToSocketServer]);

  return { socket, breakingNewsUpdates, liveCoverageUpdates };
};

'use client';

import { BreakingNew } from '@/lib/model';
import clsx from 'clsx';
import { EmblaOptionsType, EmblaCarouselType } from 'embla-carousel';
import React, { useCallback, useEffect, useState } from 'react';
import { Carousel, CarouselContent, CarouselItem } from '../carousel';
import AutoPlay from 'embla-carousel-autoplay';
import { DropdownIcon, DropUpIcon } from '../icons';
import { useGetChannelPref } from '@/providers/ChannelProvider';
import { TRT_CAROUSEL_BREAKING_NEWS } from '../header/constant';
import { FromNowDateClient } from '../date/FromNowDateClient';

const options: EmblaOptionsType = {
  loop: true,
  axis: 'y',
};

type Props = {
  breakingNews?: BreakingNew[];
  setCarouselApi: React.Dispatch<
    React.SetStateAction<EmblaCarouselType | null>
  >;
  emblaApi: EmblaCarouselType | null;
};

export function BreakingNewsContent({
  breakingNews,
  setCarouselApi,
  emblaApi,
}: Props) {
  const channelPref = useGetChannelPref();
  const [selectedItemHeight, setSelectedItemHeight] = useState<number>(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showDescription, setShowDescription] = useState(false);

  const calculateStickyThreshold = useCallback(() => {
    const currentChildItem =
      typeof document !== 'undefined'
        ? document?.getElementById(`carousel-child-${selectedIndex}`)
        : undefined;

    setSelectedItemHeight(currentChildItem?.offsetHeight || 0);
  }, [selectedIndex]);

  useEffect(() => {
    calculateStickyThreshold();
    window.addEventListener('resize', calculateStickyThreshold);

    return () => {
      window.removeEventListener('resize', calculateStickyThreshold);
    };
  }, [calculateStickyThreshold]);

  const onSlideChange = useCallback(() => {
    if (!emblaApi) return;
    const currentSlide = emblaApi.selectedScrollSnap();
    setSelectedIndex(currentSlide);
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.on('select', onSlideChange);
  }, [emblaApi, onSlideChange]);

  const onClickArrow = (value: boolean) => {
    setShowDescription(value);
    setTimeout(() => {
      calculateStickyThreshold();
    }, 200);
  };

  const truncateString = (
    input: string | null | undefined,
    count: number,
  ): string => {
    if (!input) return '';
    return input.length > count ? input.substring(0, count) : input;
  };

  const onDateFound = () => {
    setTimeout(() => {
      calculateStickyThreshold();
    }, 200);
  };

  return (
    <div className="flex flex-col justify-center px-3 md:px-4 pt-3 pb-2 sm:pt-[11px] sm:pb-[11px] lg:pt-3 lg:pb-5 xl:pt-5 xl:pb-5">
      <Carousel
        setApi={api => setCarouselApi(api)}
        opts={options}
        plugins={[AutoPlay()]}
        orientation="vertical"
        className="w-full"
        id={TRT_CAROUSEL_BREAKING_NEWS}
      >
        <CarouselContent
          style={{ height: selectedItemHeight }}
          className="mt-0 lg:!h-full transition-[height] duration-200 ease-in-out"
        >
          {breakingNews?.map((item, index) => {
            return (
              <CarouselItem
                className={clsx(
                  'flex items-center justify-center pt-0 select-none',
                )}
                key={item.id}
                id={`carousel-${index}`}
              >
                <div
                  id={`carousel-child-${index}`}
                  className={clsx(`w-full flex flex-col gap-1 items-start`)}
                >
                  <div className="w-full flex flex-col">
                    <div className="font-sf text-xs font-extrabold uppercase tracking-[1px] text-white text-nowrap">
                      <FromNowDateClient
                        date={item?.updatedAt}
                        languageCode={channelPref?.languageCode}
                        onDateFound={onDateFound}
                      />
                    </div>
                    <div className="font-playfair text-lg lg:text-1.5xl xl:text-2xl leading-normal lg:leading-custom_5 xl:leading-8 text-white font-medium">
                      {truncateString(item?.title, 100)}
                    </div>
                  </div>

                  <div className="hidden sm:block font-notosans text-xs2 md:text-sm lg:text-base font-light leading-normal text-white">
                    {truncateString(item?.description, 150)}
                  </div>

                  <div
                    className={clsx(
                      'sm:hidden block font-notosans text-xs2 font-light leading-normal text-white ease-in-out duration-200',
                      {
                        'opacity-0 !h-0': !showDescription,
                      },
                    )}
                  >
                    {truncateString(item?.description, 150)}
                  </div>
                </div>
              </CarouselItem>
            );
          })}
        </CarouselContent>
      </Carousel>

      <div className="flex sm:hidden justify-center pt-1 mx-auto z-10">
        {!showDescription ? (
          <DropdownIcon
            onClick={() => {
              onClickArrow(true);
            }}
            className="text-white w-3 h-3"
          />
        ) : (
          <DropUpIcon
            onClick={() => {
              onClickArrow(false);
            }}
            className="text-white w-6 h-6"
          />
        )}
      </div>
    </div>
  );
}

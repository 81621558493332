'use client';
import { BreakingNew } from '@/lib/model';
import clsx from 'clsx';
import { EmblaCarouselType } from 'embla-carousel';
import React, { useCallback, useEffect, useState } from 'react';

type Props = {
  emblaApi: EmblaCarouselType | null;
  breakingNews?: BreakingNew[];
};
export function BreakingNewsDots({ emblaApi, breakingNews }: Props) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onSlideChange = useCallback(() => {
    if (!emblaApi) return;
    const currentSlide = emblaApi.selectedScrollSnap();
    setSelectedIndex(currentSlide);
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.on('select', onSlideChange);
  }, [emblaApi, onSlideChange]);

  if (!breakingNews || breakingNews.length <= 1) return null;

  return (
    breakingNews &&
    breakingNews?.length > 1 && (
      <div className="ms-4">
        <div className="flex justify-center items-center h-full gap-[7px] flex-col">
          {breakingNews.map((f, index) => (
            <div
              key={`dot-${f.id}`}
              className={clsx(
                'bg-white rounded-full ease-in-out duration-500 aspect-square',
                {
                  'w-1.5 h-1.5': index === selectedIndex,
                  'w-1 h-1 opacity-50': index !== selectedIndex,
                },
              )}
            />
          ))}
        </div>
      </div>
    )
  );
}
